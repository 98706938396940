/* istanbul ignore file -- @preserve */
import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router';
import { landingPageRedirectionGuard } from '@/router/landingPageRedirectGuard';
import { autorizationNavigationGuard } from '@/router/authenticationNavigationGuard';

declare module 'vue-router' {
    interface RouteMeta {
        name: string;
        isPublic?: boolean;
    }
}

export const ROOT_PATH = '/';
export const LOGIN_PATH = '/login';

export const ROUTE_NAMES = {
    HOME: 'home',
    LOGIN: 'login',
    CHANNELS: 'channels',
    CHANNEL_NEW: 'channel-new',
    CHANNEL_EDIT: 'channel-edit',
    DEVICES: 'devices',
    DEVICE_NEW: 'device-new',
    DEVICE_EDIT: 'device-edit',
    DISTRIBUTORS: 'distributors',
    DISTRIBUTOR_NEW: 'distributor-new',
    DISTRIBUTOR_EDIT: 'distributor-edit',
    EXHIBITORS: 'exhibitors',
    EXHIBITOR_NEW: 'exhibitor-new',
    EXHIBITOR_EDIT: 'exhibitor-edit',
    LOCATIONS: 'locations',
    LOCATION_EDIT: 'location-edit',
    LOCATION_NEW: 'location-new',
    LOCATION_GROUPS: 'location-groups',
    LOCATION_GROUP_EDIT: 'location-group-edit',
    LOCATION_GROUP_NEW: 'location-group-new',
    PROJECTION_TYPES: 'projection-type',
    PROJECTION_TYPE_EDIT: 'projection-type-edit',
    PROJECTION_TYPE_NEW: 'projection-type-new',
};

export type MenuRouteKey = keyof Pick<
    typeof ROUTE_NAMES,
    | 'HOME'
    | 'CHANNELS'
    | 'DEVICES'
    | 'DISTRIBUTORS'
    | 'EXHIBITORS'
    | 'LOCATIONS'
    | 'LOCATION_GROUPS'
    | 'PROJECTION_TYPES'
>;

export const MENU_ROUTES: Record<MenuRouteKey, RouteRecordRaw> = {
    HOME: {
        path: '/home',
        name: ROUTE_NAMES.HOME,
        component: () => import('@/pages/HomePage.vue'),
        meta: {
            name: 'Home',
        },
    },
    CHANNELS: {
        path: '/channels',
        name: ROUTE_NAMES.CHANNELS,
        component: () => import('@/pages/Channels/ChannelsPage.vue'),
        meta: {
            name: 'Channels',
        },
    },
    DEVICES: {
        path: '/devices',
        name: ROUTE_NAMES.DEVICES,
        component: () => import('@/pages/Devices/DevicesPage.vue'),
        meta: {
            name: 'Devices',
        },
    },
    DISTRIBUTORS: {
        path: '/distributors',
        name: ROUTE_NAMES.DISTRIBUTORS,
        component: () => import('@/pages/Distributors/DistributorsPage.vue'),
        meta: {
            name: 'Distributors',
        },
    },
    EXHIBITORS: {
        path: '/exhibitors',
        name: ROUTE_NAMES.EXHIBITORS,
        component: () => import('@/pages/Exhibitors/ExhibitorsPage.vue'),
        meta: {
            name: 'Exhibitors',
        },
    },
    LOCATIONS: {
        path: '/locations',
        name: ROUTE_NAMES.LOCATIONS,
        component: () => import('@/pages/Locations/LocationsPage.vue'),
        meta: {
            name: 'Locations',
        },
    },
    LOCATION_GROUPS: {
        path: '/location-groups',
        name: ROUTE_NAMES.LOCATION_GROUPS,
        component: () => import('@/pages/LocationGroups/LocationGroupsPage.vue'),
        meta: {
            name: 'Location Groups',
        },
    },
    PROJECTION_TYPES: {
        path: '/projection-types',
        name: ROUTE_NAMES.PROJECTION_TYPES,
        component: () => import('@/pages/ProjectionTypes/ProjectionTypesPage.vue'),
        meta: {
            name: 'Projection Types',
        },
    },
};

export const routes = [
    {
        path: ROOT_PATH,
        component: () => import('@/layout/MainLayout.vue'),
        children: [
            ...Object.values(MENU_ROUTES),
            {
                path: '/channels/edit/:key',
                name: ROUTE_NAMES.CHANNEL_EDIT,
                component: () => import('@/pages/Channels/ChannelEditPage.vue'),
                meta: {
                    name: 'Edit Channel',
                },
            },
            {
                path: '/channels/new',
                name: ROUTE_NAMES.CHANNEL_NEW,
                component: () => import('@/pages/Channels/ChannelAddPage.vue'),
                meta: {
                    name: 'New Channel',
                },
            },
            {
                path: '/devices/edit/:key',
                name: ROUTE_NAMES.DEVICE_EDIT,
                component: () => import('@/pages/Devices/DeviceEditPage.vue'),
                meta: {
                    name: 'Edit Device',
                },
            },
            {
                path: '/devices/new',
                name: ROUTE_NAMES.DEVICE_NEW,
                component: () => import('@/pages/Devices/DeviceAddPage.vue'),
                meta: {
                    name: 'New Device',
                },
            },
            {
                path: '/distributors/edit/:key',
                name: ROUTE_NAMES.DISTRIBUTOR_EDIT,
                component: () => import('@/pages/Distributors/DistributorEditPage.vue'),
                meta: {
                    name: 'Edit Distributor',
                },
            },
            {
                path: '/distributors/new',
                name: ROUTE_NAMES.DISTRIBUTOR_NEW,
                component: () => import('@/pages/Distributors/DistributorAddPage.vue'),
                meta: {
                    name: 'New Distributor',
                },
            },
            {
                path: '/exhibitors/edit/:key',
                name: ROUTE_NAMES.EXHIBITOR_EDIT,
                component: () => import('@/pages/Exhibitors/ExhibitorEditPage.vue'),
                meta: {
                    name: 'Edit Exhibitor',
                },
            },
            {
                path: '/exhibitors/new',
                name: ROUTE_NAMES.EXHIBITOR_NEW,
                component: () => import('@/pages/Exhibitors/ExhibitorAddPage.vue'),
                meta: {
                    name: 'New Exhibitor',
                },
            },
            {
                path: '/locations/new',
                name: ROUTE_NAMES.LOCATION_NEW,
                component: () => import('@/pages/Locations/LocationAddPage.vue'),
                meta: {
                    name: 'Add Location',
                },
            },
            {
                path: '/locations/edit/:key',
                name: ROUTE_NAMES.LOCATION_EDIT,
                component: () => import('@/pages/Locations/LocationEditPage.vue'),
                meta: {
                    name: 'Edit Location',
                },
            },
            {
                path: '/location-groups/edit/:key',
                name: ROUTE_NAMES.LOCATION_GROUP_EDIT,
                component: () => import('@/pages/LocationGroups/LocationGroupEditPage.vue'),
                meta: {
                    name: 'Edit Location Group',
                },
            },
            {
                path: '/location-groups/new',
                name: ROUTE_NAMES.LOCATION_GROUP_NEW,
                component: () => import('@/pages/LocationGroups/LocationGroupAddPage.vue'),
                meta: {
                    name: 'New Location Group',
                },
            },
            {
                path: '/projection-types/edit/:id',
                name: ROUTE_NAMES.PROJECTION_TYPE_EDIT,
                component: () => import('@/pages/ProjectionTypes/ProjectionTypeEditPage.vue'),
                meta: {
                    name: 'Edit Projection Type',
                },
            },
            {
                path: '/projection-types/new',
                name: ROUTE_NAMES.PROJECTION_TYPE_NEW,
                component: () => import('@/pages/ProjectionTypes/ProjectionTypeAddPage.vue'),
                meta: {
                    name: 'New Projection Type',
                },
            },
        ],
    },
    {
        path: LOGIN_PATH,
        component: () => import('@/pages/LoginPage.vue'),
        meta: {
            name: 'Login',
            isPublic: true,
        },
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});
router.beforeEach(autorizationNavigationGuard);
router.beforeEach(landingPageRedirectionGuard);

export { router };
